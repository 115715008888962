<template>
  <div>
    <!--Header-->
    <div class="header bg-success pt-5 pb-7">
      <b-container>
        <div class="header-body">
          <b-row align-v="center" >
            <b-col lg="6">
              <div class="pr-5">
                <h1 class="display-2 text-white font-weight-bold mb-0">Next Generation Rugby Scouting</h1>
                <h2 class="display-4 text-white font-weight-light">Artificial Intelligence Assisted</h2>
                <p class="text-white mt-4"><b>Unleash Your Potential.</b> Sportzie connects your talent with scouts, sports organizations, and brands worldwide. Take on challenges like 40-yard dashes, vertical jumps, and more to showcase your skills and stand out.</p>
                <div class="mt-5">
                  <a href="https://apps.apple.com/us/app/sportzieapp/id6449923675?platform=iphone" target="_blank"
              class="btn btn-neutral btn-icon">
             <span class="nav-link-inner--text"><i class="ni ni-active-40"></i>App Store</span>
           </a>
           <a href="https://play.google.com/store/apps/details?id=com.sportzie.betaapp" target="_blank"
              class="btn btn-neutral btn-icon">
             <span class="nav-link-inner--text"><i class="ni ni-active-40"></i>Google Store</span>
           </a>
                  <a href="#whois" class="btn btn-default my-2">Tell me more</a>
                </div>
                <div>
                  <img style="width:350px; margin-top: 75px;" src="img/brand/sportzie_logo.png"/>
                </div>
              </div>
            </b-col>
            <b-col lg="6">
              <b-row class="pt-5">
                <b-col md="6">
                  <b-card no-body>
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Rugby Players</h5>
                      <p>Showcase your talent and connect with scouts worldwide. Take on challenges and get noticed by top clubs.</p>
                    </b-card-body>
                  </b-card>
                  <b-card no-body>
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Scouts & Clubs</h5>
                      <p>Discover and recruit top rugby talent from around the globe. Access detailed profiles and highlight videos.</p>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col md="6" class="pt-lg-5 pt-4">
                  <b-card no-body class="mb-4">
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Video Experiences</h5>
                      <p>Create and share compelling highlight reels. Use our AI tools to make your skills stand out.</p>
                    </b-card-body>
                  </b-card>
                  <b-card no-body class="mb-4">
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Brands</h5>
                      <p>Find the perfect athletes to represent your brand. Connect with emerging rugby talent and brand ambassadors.</p>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <section id="whois" class="py-6 pb-9 bg-default">
      <b-row class="justify-content-center text-center">
        <b-col md="6">
          <h2 class="display-3 text-black">Connecting Rugby Talent to Scouts & Clubs</h2>
          <p class="lead text-black">
            Our platform connects Global Rugby Players to <b> Scouts, Rugby Clubs, Sports Recruiters and even Brands</b>.
          </p>
        </b-col>
      </b-row>
    </section>
    <section class="section section-lg pt-lg-0 mt--7">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="12" >
            <b-row >
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body class="py-5">
                    <div class="icon icon-shape bg-gradient-primary text-white rounded-circle mb-4">
                      <i class="ni ni-check-bold"></i>
                    </div>
                    <h4 class="h3 text-primary text-uppercase">Rugby Players</h4>
                    <p class="description mt-3">
                      <ul>
                        <li><b>Create and Customize: </b> Craft your profile and update your accomplishments anytime. </li>
                        <li><b>Showcase Your Talent: </b>Upload videos to highlight your skills and achievements.</li>
                        <li><b>Take on Challenges: </b>Complete Sportzie challenges designed to showcase your potential.</li>
                        <li><b>Get Noticed: </b>Connect with scouts, clubs, and brands worldwide.</li>
                        <li><b>Coming Soon: </b> AI-assisted highlight creation for your best moments.</li>
                      </ul>
                     </p>
                    <!-- <div>
                      <b-badge pill variant="primary">Videos</b-badge>
                      <b-badge pill variant="primary">Challenges</b-badge>
                      <b-badge pill variant="primary">Get Scouted</b-badge>
                    </div> -->
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body  class="py-5">
                    <div class="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                      <i class="ni ni-istanbul"></i>
                    </div>
                    <h4 class="h3 text-success text-uppercase">Scouts & Rugby Clubs</h4>
                    <p class="description mt-3">
                      <ul>
                        <li><b>Discover Talent Globally:</b> Access a vast pool of rugby players from around the world.</li>
                          <li><b>Detailed Player Profiles:</b> View comprehensive profiles with stats, achievements, and video highlights.</li>
                            <li><b>Advanced Search Filters:</b> Use powerful filters to find players that match your specific criteria.</li>
                              <li><b>Direct Communication:</b> Easily contact players to discuss opportunities and recruitment.</li>
                      </ul>
                    </p>
                    <!-- <div>
                      <b-badge pill variant="success">npm</b-badge>
                      <b-badge pill variant="success">gulp</b-badge>
                      <b-badge pill variant="success">build tools</b-badge>
                    </div> -->
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body class="py-5">
                    <div class="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                      <i class="ni ni-planet"></i>
                    </div>
                    <h4 class="h3 text-warning text-uppercase">Brands</h4>
                    <p class="description mt-3">
                      <ul>
                        <li><b>Identify Potential Ambassadors:</b> Find athletes who align with your brand values and vision.</li>
                          <li><b>Comprehensive Athlete Profiles:</b> Access detailed profiles showcasing players’ skills, achievements, and personality.</li>
                            <li><b>Engage with Emerging Talent:</b> Connect with up-and-coming rugby players who have the potential to elevate your brand.</li>
                              <li><b>Collaborative Opportunities:</b> Create meaningful partnerships and sponsorship deals with talented athletes.</li>
                      </ul>
                    </p>
                    <!-- <div>
                      <b-badge pill variant="warning">sass</b-badge>
                      <b-badge pill variant="warning">design</b-badge>
                      <b-badge pill variant="warning">customize</b-badge>
                    </div> -->
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="features" class="py-6">
      <b-container>
        <b-row align-v="center" class="row-grid">
          <b-col md="6" class="order-md-2">
            <b-img src="img/theme/athlete.png" fluid />
          </b-col>
          <b-col md="6" class="order-md-1">
            <div class="pr-md-5">
              <h1>Rugby Player Features</h1>
              <h3>Enhance Your Rugby Journey</h3>
              <p>Record videos anytime to enhance your profile. Tag your videos, and our AI will help you get noticed, connecting you with potential clubs. Our challenges provide an additional platform to showcase your athletic potential.</p>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-settings-gear-65"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">Upload and tag videos and get scouted.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-html5"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">Take challenges created by Sportzie.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-satisfied"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">Get discovered by Clubs, Scouts & Brands.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-satisfied"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">Apply to global rugby opportunities posted by clubs.</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="py-6">
      <b-container>
        <b-row align-v="center" class="row-grid">
          <b-col md="6">
            <b-img src="img/theme/Scout.png" fluid />
          </b-col>
          <b-col md="6">
            <div class="pr-md-5">
              <h1>Scouting & Club Features</h1>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-settings-gear-65"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Discover Talent Globally:</strong></span> Access a vast pool of rugby players from around the world.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-html5"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Detailed Player Profiles:</strong></span> View comprehensive profiles with stats, achievements, and video highlights.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-satisfied"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Advanced Search Filters:</strong></span> Use powerful filters to find players that match your specific criteria.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-satisfied"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Direct Communication:</strong></span> Easily contact players to discuss opportunities and recruitment.</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="py-6">
      <b-container>
        <b-row align-v="center" class="row-grid">
          <b-col md="6" class="order-md-2">
            <b-img src="img/theme/brand1.webp" style="height:600px" fluid />
          </b-col>
          <b-col md="6" class="col-md-6">
            <div class="pr-md-5">
              <h1>Brands</h1>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-settings-gear-65"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Discover Talent:</strong></span> Identify emerging rugby players who can represent your brand and resonate with your audience.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-html5"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Detailed Insights:</strong></span> Access in-depth profiles with stats, achievements, and highlight videos to find the perfect match.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-satisfied"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Direct Engagement:</strong></span> Communicate directly with potential brand ambassadors to discuss partnership opportunities.</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-satisfied"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0"><span class="lead text-black"><strong>Sponsorship Opportunities:</strong></span> Create impactful sponsorships and collaborations with talented athletes to boost your brand visibility.</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="py-7 section-nucleo-icons bg-green overflow-hidden">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="8" class="text-center">
            <h2 class="display-3 text-white">Stay in touch</h2>
            <p class="lead text-white">
              Sign up now to receive the latest updates, exclusive access to our early launch, and be the first to know about new features and opportunities.
            </p>
            <div class="btn-wrapper">
              <a href="/#/betasignup" target="_blank" class="btn btn-default mt-3 mt-md-0">Stay in touch</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="pricing" class="py-7">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="8" class="text-center" style="margin-bottom: 30px;">
            <h2 class="display-3">Pricing</h2>
            <p>We completely free for now. We will release an advanced AI subscription early 2025. If you are a brand looking to signup please contuct us <a href="/betasignup">here</a>.</p>
          </b-col>
        </b-row>
      <b-row class="justify-content-center">
        <b-col lg="10">
          <b-card-group class="pricing flex-column flex-md-row mb-3">
            <card header-classes="bg-transparent"
                  body-classes="px-lg-7"
                  class="card-pricing border-0 text-center mb-4">
              <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Players</h4>

              <div class="display-2">Early Access</div>
              <span class="text-muted">free through 2024</span>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white">
                        <i class="fas fa-terminal"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">Access to global clubs</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white">
                        <i class="fas fa-pen-fancy"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">Build your profile with video</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white">
                        <i class="fas fa-hdd"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2">All Features</span>
                    </div>
                  </div>
                </li>
              </ul>

              <a href="https://apps.apple.com/us/app/sportzieapp/id6449923675?platform=iphone" target="_blank"
              class="btn btn-neutral btn-icon">
             <span class="nav-link-inner--text"><i class="ni ni-active-40"></i>App Store</span>
           </a>
           <a href="https://play.google.com/store/apps/details?id=com.sportzie.betaapp" target="_blank"
              class="btn btn-neutral btn-icon">
             <span class="nav-link-inner--text"><i class="ni ni-active-40"></i>Google Store</span>
           </a>

              <a slot="footer" href="#!" class="text-light">No credit card needed</a>
            </card>

            <card gradient="default"
                  class="card-pricing zoom-in shadow-lg rounded border-0 text-center mb-4"
                  header-classes="bg-transparent"
                  body-classes="px-lg-7"
                  footer-classes="bg-transparent"
            >
              <h4 slot="header" class="text-uppercase ls-1 text-dark py-3 mb-0">Clubs/Scouts/Placement Organizations</h4>
              <div class="display-2 text-dark">Early Access</div>
              <span class="text-dark">free through 2024</span>
              <!-- <h2 class="text-dark">free through 2024</h2>
              <span class="text-dark">1-10 licenses (small orgs)</span>
              <div class="text-dark">$175 monthly</div>
              <br/><br/>
              <span class="text-dark">Unlimted licenses (large orgs)</span>
              <div class="text-dark">$400 monthly</div> -->

              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                        <i class="fas fa-terminal"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-dark">All Features</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                        <i class="fas fa-pen-fancy"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-dark">Post Openings</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                        <i class="fas fa-hdd"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-dark">Find Global Rugby Talent</span>
                    </div>
                  </div>
                </li>
              </ul>
              <a href="https://apps.apple.com/us/app/sportzieapp/id6449923675?platform=iphone" target="_blank"
              class="btn btn-neutral btn-icon">
             <span class="nav-link-inner--text"><i class="ni ni-active-40"></i>App Store</span>
           </a>
           <a href="https://play.google.com/store/apps/details?id=com.sportzie.betaapp" target="_blank"
              class="btn btn-neutral btn-icon">
             <span class="nav-link-inner--text"><i class="ni ni-active-40"></i>Google Store</span>
           </a>
              <a slot="footer" href="#!" class="text-dark">No credit card needed</a>
            </card>
          </b-card-group>
        </b-col>
      </b-row>

    </b-container>
    </section>
    <section class="py-7 bg-default">
      <b-container>
        <b-row class="row-grid justify-content-center">
          <b-col lg="8" class="text-center">
            <h2 class="display-3 text-dark">Join the Sportzie Community</h2>
            <p class="lead text-dark">Elevate your rugby journey with Sportzie.
                Whether you're a player looking to get scouted, a scout searching
                for top talent, or a brand seeking the perfect ambassador, Sportzie
                  connects you with the right opportunities.</p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
  import { Tooltip } from 'element-ui'
  export default {
    name: 'home-page',
    components: {
      [Tooltip.name]: Tooltip
    }
  };
</script>
