<template>
  <div>
    <!--Header-->
    <div class="header bg-success pt-5 pb-7">
      <b-container>
        <div class="header-body">
          <b-row align-v="center" >
            <b-col lg="12">
              <div class="pr-5 text-white">
                <h1 class="text-white">Privacy Policy</h1>
  <p><strong>Last Updated:</strong> 2024/08/03</p>

  <h2>1. Introduction</h2>
  <p>Welcome to Sportzie! This Privacy Policy explains how Sportzie ("we," "us," or "our") collects, uses, discloses, and safeguards your information when you use our mobile application ("App") and website <a href="https://sportzie.io">https://sportzie.io</a> (collectively, the "Services"). By accessing or using our Services, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>

  <h2>2. Information We Collect</h2>
  <p><strong>a. Personal Information</strong><br>
  We may collect personal information that you voluntarily provide to us when you register for an account, upload content, or communicate with us. This may include:</p>
  <ul>
    <li>Name</li>
    <li>Email address</li>
    <li>Profile picture</li>
    <li>Location</li>
    <li>Videos and other content you upload</li>
  </ul>

  <p><strong>b. Usage Data</strong><br>
  We may automatically collect certain information about your device and how you interact with our Services. This may include:</p>
  <ul>
    <li>Device information (e.g., IP address, device type, operating system)</li>
    <li>Usage data (e.g., pages viewed, time spent on pages, interactions with the app)</li>
    <li>Cookies and similar technologies</li>
  </ul>

  <p><strong>c. Third-Party Information</strong><br>
  We may receive information from third-party services, such as social media platforms, when you connect those accounts to our Services.</p>

  <h2>3. How We Use Your Information</h2>
  <p>We use the information we collect for various purposes, including:</p>
  <ul>
    <li>To provide, operate, and maintain our Services</li>
    <li>To improve, personalize, and expand our Services</li>
    <li>To communicate with you, including sending updates, newsletters, and marketing materials</li>
    <li>To process transactions and manage your account</li>
    <li>To monitor and analyze usage and trends</li>
    <li>To protect against fraud and ensure the security of our Services</li>
  </ul>

  <h2>4. Sharing Your Information</h2>
  <p>We may share your information with:</p>
  <ul>
    <li><strong>Service Providers:</strong> Third-party vendors that assist us in providing our Services (e.g., hosting, analytics, customer support).</li>
    <li><strong>Business Partners:</strong> Organizations we collaborate with for events, promotions, or co-branded offerings.</li>
    <li><strong>Legal and Regulatory Authorities:</strong> When required by law, legal processes, or regulations.</li>
    <li><strong>Other Users:</strong> Content you upload, such as videos, may be visible to other users of the Services.</li>
  </ul>

  <h2>5. Your Choices and Rights</h2>
  <p><strong>Access and Update Information:</strong> You can access and update your account information through your profile settings.<br>
  <strong>Data Retention:</strong> We will retain your personal information as long as necessary for the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.<br>
  <strong>Delete Account:</strong> You may request to delete your account and personal information by contacting us at [contact email].</p>

  <h2>6. Security</h2>
  <p>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no security measures are 100% secure, and we cannot guarantee the absolute security of your information.</p>

  <h2>7. Children’s Privacy</h2>
  <p>Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.</p>

  <h2>8. Changes to This Privacy Policy</h2>
  <p>We may update this Privacy Policy from time to time. We will notify you of any changes by updating the "Last Updated" date at the top of this policy and, in some cases, provide additional notice. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

  <h2>9. Contact Us</h2>
  <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
  <p><strong>Sportzie</strong><br>
  Email: info@sportzie.io<br>
  Address: Austin, TX, 78738</p>
                </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
  import { Tooltip } from 'element-ui'
  export default {
    name: 'home-page',
    components: {
      [Tooltip.name]: Tooltip
    }
  };
</script>
