<template>
  <div>
    <!--Header-->
    <div class="header bg-success pt-5 pb-7">
      <b-container>
        <div class="header-body">
          <b-row align-v="center" >
            <b-col lg="6">
              <div class="pr-5">
                <h1 class="display-2 text-white font-weight-bold mb-0">Early Access</h1>
                <h2 class="display-4 text-white font-weight-light">Artificial Intelligence Assisted</h2>
                <p class="text-white mt-4"><b>Unleash Your Potential.</b> Sportzie connects your talent with scouts, sports organizations, and brands worldwide. Take on challenges like 40-yard dashes, vertical jumps, and more to showcase your skills and stand out.</p>
              </div>
            </b-col>
            <b-col lg="6">
              <b-row class="pt-5">
                <b-col md="12">
                  <div class="sib-form" style="text-align: center; background-color: #eff2f7">
  <div id="sib-form-container" class="sib-form-container">
    <div
      id="sib-container"
      class="sib-container--large sib-container--vertical"
      style="
        text-align: center;
        background-color: rgba(255, 255, 255, 1);
        max-width: 540px;
        border-radius: 3px;
        border-width: 1px;
        border-color: #c0ccd9;
        border-style: solid;
      "
    >
      <form
        id="sib-form"
        method="POST"
        action="https://db4528aa.sibforms.com/serve/MUIFAOvjnoSIHXKxRLylH-rBJmUYrfxdbQugtJviNAnWU-i7_ZGcnjiQzK6vsiDx5E-VMn2SimBf9PNosC0BQ011XuTpxNAJkgX-0Xf98GZNUV0i_waV9m3_a7NVAnp9sj2h7nMp4r3RCQIX9hSp5AvlA8MW3qlOgjmMprD51wp7ZAHqTjPNQpMASW-mLaQCD3gAth4N9sFP2mnl"
      >
        <div style="padding: 8px 0">
          <div
            class="sib-form-block"
            style="
              font-size: 32px;
              text-align: left;
              font-weight: 700;
              font-family: 'Helvetica', sans-serif;
              color: #3c4858;
              background-color: transparent;
              text-align: left;
            "
          >
            <p>Early Access</p>
          </div>
        </div>
        <div style="padding: 8px 0">
          <div
            class="sib-form-block"
            style="
              font-size: 16px;
              text-align: left;
              font-family: 'Helvetica', sans-serif;
              color: #3c4858;
              background-color: transparent;
              text-align: left;
            "
          >
            <div class="sib-text-form-block">
              <p>We are excited to have you onboard. Tell us more about you.</p>
            </div>
          </div>
        </div>
        <div style="padding: 8px 0">
          <div class="sib-input sib-form-block">
            <div class="form__entry entry_block">
              <div class="form__label-row">
                <label
                  class="entry__label"
                  style="
                    font-weight: 700;
                    text-align: left;
                    font-size: 16px;
                    text-align: left;
                    font-weight: 700;
                    font-family: 'Helvetica', sans-serif;
                    color: #3c4858;
                  "
                  for="FIRSTNAME"
                  data-required="*"
                  >What&#039;s your name?</label
                >
                <div class="entry__field">
                  <input
                    class="input"
                    maxlength="200"
                    type="text"
                    id="FIRSTNAME"
                    name="FIRSTNAME"
                    autocomplete="off"
                    placeholder="NAME"
                    data-required="true"
                    required
                  />
                </div>
              </div>
              <label
                class="entry__error entry__error--primary"
                style="
                  font-size: 16px;
                  text-align: left;
                  font-family: 'Helvetica', sans-serif;
                  color: #661d1d;
                  background-color: #ffeded;
                  border-radius: 3px;
                  border-color: #ff4949;
                "
              >
              </label>
              <label
                class="entry__specification"
                style="
                  font-size: 12px;
                  text-align: left;
                  font-family: 'Helvetica', sans-serif;
                  color: #8390a4;
                  text-align: left;
                "
              >
                <!-- Customize this optional help text before publishing your form. -->
              </label>
            </div>
          </div>
        </div>
        <div style="padding: 8px 0">
          <div class="sib-input sib-form-block">
            <div class="form__entry entry_block">
              <div class="form__label-row">
                <label
                  class="entry__label"
                  style="
                    font-weight: 700;
                    text-align: left;
                    font-size: 16px;
                    text-align: left;
                    font-weight: 700;
                    font-family: 'Helvetica', sans-serif;
                    color: #3c4858;
                  "
                  for="EMAIL"
                  data-required="*"
                  >Enter your email address to subscribe</label
                >
                <div class="entry__field">
                  <input
                    class="input"
                    type="text"
                    id="EMAIL"
                    name="EMAIL"
                    autocomplete="off"
                    placeholder="EMAIL"
                    data-required="true"
                    required
                  />
                </div>
              </div>
              <label
                class="entry__error entry__error--primary"
                style="
                  font-size: 16px;
                  text-align: left;
                  font-family: 'Helvetica', sans-serif;
                  color: #661d1d;
                  background-color: #ffeded;
                  border-radius: 3px;
                  border-color: #ff4949;
                "
              >
              </label>
              <label
                class="entry__specification"
                style="
                  font-size: 12px;
                  text-align: left;
                  font-family: 'Helvetica', sans-serif;
                  color: #8390a4;
                  text-align: left;
                "
              >
                Provide your email address to subscribe. For e.g abc@xyz.com
              </label>
            </div>
          </div>
        </div>
        <div style="padding: 8px 0">
          <div class="sib-input sib-form-block">
            <div class="form__entry entry_block">
              <div class="form__label-row">
                <label
                  class="entry__label"
                  style="
                    font-weight: 700;
                    text-align: left;
                    font-size: 16px;
                    text-align: left;
                    font-weight: 700;
                    font-family: 'Helvetica', sans-serif;
                    color: #3c4858;
                  "
                  for="SPORTS"
                  >Enter your Positions</label
                >
                <div class="entry__field">
                  <input
                    class="input"
                    maxlength="200"
                    type="text"
                    id="SPORTS"
                    name="SPORTS"
                    autocomplete="off"
                    placeholder="POSITIONS"
                    data-required="true"
                    required
                  />
                </div>
              </div>
              <label
                class="entry__error entry__error--primary"
                style="
                  font-size: 16px;
                  text-align: left;
                  font-family: 'Helvetica', sans-serif;
                  color: #661d1d;
                  background-color: #ffeded;
                  border-radius: 3px;
                  border-color: #ff4949;
                "
              >
              </label>
              <label
                class="entry__specification"
                style="
                  font-size: 12px;
                  text-align: left;
                  font-family: 'Helvetica', sans-serif;
                  color: #8390a4;
                  text-align: left;
                "
              >
                If you are a player, tell us what positions you play
              </label>
            </div>
          </div>
        </div>
        <div style="padding: 8px 0">
          <div
            class="sib-radiobutton-group sib-form-block"
            data-required="true"
          >
            <div class="form__entry entry_mcq">
              <div class="form__label-row">
                <label
                  class="entry__label"
                  style="
                    font-weight: 700;
                    text-align: left;
                    font-size: 16px;
                    text-align: left;
                    font-weight: 700;
                    font-family: 'Helvetica', sans-serif;
                    color: #3c4858;
                  "
                  for="ROLE"
                  data-required="*"
                  >What is your role?</label
                >
                <div>
                  <div class="entry__choice" style="">
                    <label>
                      <input type="radio" name="ROLE" class="input_replaced"
                      value="1" required>
                      <span class="radio-button" style="margin-left: "></span
                      ><span
                        style="
                          font-size: 16px;
                          text-align: left;
                          font-family: 'Helvetica', sans-serif;
                          color: #3c4858;
                          background-color: transparent;
                        "
                        >Rugby Player</span
                      >
                    </label>
                  </div>
                  <div class="entry__choice" style="">
                    <label>
                      <input type="radio" name="ROLE" class="input_replaced"
                      value="2" required>
                      <span class="radio-button" style="margin-left: "></span
                      ><span
                        style="
                          font-size: 16px;
                          text-align: left;
                          font-family: 'Helvetica', sans-serif;
                          color: #3c4858;
                          background-color: transparent;
                        "
                        >Scout/Org/Sports Recruiter</span
                      >
                    </label>
                  </div>
                  <div class="entry__choice" style="">
                    <label>
                      <input type="radio" name="ROLE" class="input_replaced"
                      value="3" required>
                      <span class="radio-button" style="margin-left: "></span
                      ><span
                        style="
                          font-size: 16px;
                          text-align: left;
                          font-family: 'Helvetica', sans-serif;
                          color: #3c4858;
                          background-color: transparent;
                        "
                        >Brand</span
                      >
                    </label>
                  </div>
                </div>
              </div>
              <label
                class="entry__error entry__error--primary"
                style="
                  font-size: 16px;
                  text-align: left;
                  font-family: 'Helvetica', sans-serif;
                  color: #661d1d;
                  background-color: #ffeded;
                  border-radius: 3px;
                  border-color: #ff4949;
                "
              >
              </label>
            </div>
          </div>
        </div>
        <div style="padding: 8px 0">
          <div class="sib-form-block" style="text-align: left">
            <button
              class="sib-form-block__button sib-form-block__button-with-loader"
              style="
                font-size: 16px;
                text-align: left;
                font-weight: 700;
                font-family: 'Helvetica', sans-serif;
                color: #ffffff;
                background-color: #3e4857;
                border-radius: 3px;
                border-width: 0px;
              "
              form="sib-form"
              type="submit"
            >
              <svg
                class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                viewBox="0 0 512 512"
              >
                <path
                  d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"
                />
              </svg>
              SUBMIT
            </button>
          </div>
        </div>
        <input
          type="text"
          name="email_address_check"
          value=""
          class="input--hidden"
        />
        <input type="hidden" name="locale" value="en" />
        <input type="hidden" name="html_type" value="simple" />
      </form>



    </div>
  </div>
</div>
                  <!-- <b-card no-body>
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Rugby Players</h5>
                      <p>Showcase your talent and connect with scouts worldwide. Take on challenges and get noticed by top clubs.</p>
                    </b-card-body>
                  </b-card>
                  <b-card no-body>
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Scouts</h5>
                      <p>Discover and recruit top rugby talent from around the globe. Access detailed profiles and highlight videos.</p>
                    </b-card-body>
                  </b-card> -->
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <section id="whois" class="py-6 pb-9 bg-default">
      <b-row class="justify-content-center text-center">
        <b-col md="6">
          <h2 class="display-3 text-black">Connecting Rugby Talent to Scouts & Clubs</h2>
          <p class="lead text-black">
            Our platform connects Global Rugby Players to <b> Scouts, Rugby Clubs, Sports Recruiters and even Brands</b>.
          </p>
        </b-col>
      </b-row>
    </section>
    <section class="section section-lg pt-lg-0 mt--7">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="12" >
            <b-row >
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body class="py-5">
                    <div class="icon icon-shape bg-gradient-primary text-white rounded-circle mb-4">
                      <i class="ni ni-check-bold"></i>
                    </div>
                    <h4 class="h3 text-primary text-uppercase">Rugby Players</h4>
                    <p class="description mt-3">
                      <ul>
                        <li><b>Create and Customize: </b> Craft your profile and update your accomplishments anytime. </li>
                        <li><b>Showcase Your Talent: </b>Upload videos to highlight your skills and achievements.</li>
                        <li><b>Take on Challenges: </b>Complete Sportzie challenges designed to showcase your potential.</li>
                        <li><b>Get Noticed: </b>Connect with scouts, clubs, and brands worldwide.</li>
                        <li><b>Coming Soon: </b> AI-assisted highlight creation for your best moments.</li>
                      </ul>
                     </p>
                    <!-- <div>
                      <b-badge pill variant="primary">Videos</b-badge>
                      <b-badge pill variant="primary">Challenges</b-badge>
                      <b-badge pill variant="primary">Get Scouted</b-badge>
                    </div> -->
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body  class="py-5">
                    <div class="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                      <i class="ni ni-istanbul"></i>
                    </div>
                    <h4 class="h3 text-success text-uppercase">Scouts & Rugby Clubs</h4>
                    <p class="description mt-3">
                      <ul>
                        <li><b>Discover Talent Globally:</b> Access a vast pool of rugby players from around the world.</li>
                          <li><b>Detailed Player Profiles:</b> View comprehensive profiles with stats, achievements, and video highlights.</li>
                            <li><b>Advanced Search Filters:</b> Use powerful filters to find players that match your specific criteria.</li>
                              <li><b>Direct Communication:</b> Easily contact players to discuss opportunities and recruitment.</li>
                      </ul>
                    </p>
                    <!-- <div>
                      <b-badge pill variant="success">npm</b-badge>
                      <b-badge pill variant="success">gulp</b-badge>
                      <b-badge pill variant="success">build tools</b-badge>
                    </div> -->
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body class="py-5">
                    <div class="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                      <i class="ni ni-planet"></i>
                    </div>
                    <h4 class="h3 text-warning text-uppercase">Brands</h4>
                    <p class="description mt-3">
                      <ul>
                        <li><b>Identify Potential Ambassadors:</b> Find athletes who align with your brand values and vision.</li>
                          <li><b>Comprehensive Athlete Profiles:</b> Access detailed profiles showcasing players’ skills, achievements, and personality.</li>
                            <li><b>Engage with Emerging Talent:</b> Connect with up-and-coming rugby players who have the potential to elevate your brand.</li>
                              <li><b>Collaborative Opportunities:</b> Create meaningful partnerships and sponsorship deals with talented athletes.</li>
                      </ul>
                    </p>
                    <!-- <div>
                      <b-badge pill variant="warning">sass</b-badge>
                      <b-badge pill variant="warning">design</b-badge>
                      <b-badge pill variant="warning">customize</b-badge>
                    </div> -->
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
  import { Tooltip } from 'element-ui'
  export default {
    name: 'home-page',
    components: {
      [Tooltip.name]: Tooltip
    }
  };
</script>
